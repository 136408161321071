import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from '@/api/teacher/center/memberList.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import { request } from "@/request/http";
export default {
        // 页面名称
        name: "colleague",
        data()
        {
            return{
                list:[],
                listTotal:0,
                searchContent:"",
                searchList:[],
                currentPage:1,
                pageSize:8,
                current_id:'',
                dialogVisible : false,
                center_id:"",
                centerList:[],
            }
        },
        created()
        {
            this.getData();
        },
        methods:
        {
            getData()
            {
                try 
                {
                    let self = this;
                    // 请求参数
                    let data = {
                        name : self.searchContent,
                        center_id : self.center_id,
                        page : self.currentPage,
                        pageSize : self.pageSize,
                    };
                    nprogressStart();
                    self.$Loading.show();
                    request("POST", `/index.php/center/getExpListBySchoolId`, data)
                        .then((result) => {
                            nprogressClose();
                            self.$Loading.hide();
                            console.log(result)
                            if(result.code > 0 )
                            {
                                self.list = result.data
                                self.listTotal = result.total;
                                self.centerList = result.centerList;
                            }
                        })              
                } 
                catch (error) 
                {
                    Message.error('系统错误') 
                }
            },

            searchMember(e)
            {
                // try 
                // {
                //     let self = this;
                //     let data = {
                //         search: e
                //     }
                //     if(e)
                //     {
                //         API.searchMember(data)
                //         .then((result)=>{
                //             if(result.code > 0 )
                //             { 
                //                 self.searchList = result.data
                //             }
                //             else
                //             {
                //                 this.$Tips({
                //                     // 消息提示内容
                //                     message:result.msg,
                //                     // 消息提示类型（success-成功,warning-警告/失败）
                //                     messageType:'warning',
                //                     displayTime:1500
                //                 })
                //             }
                //         })
                //     }
                // } 
                // catch (error) 
                // {
                //     Message.error('系统错误')
                // }
            }
        }       
};